
import axios from 'axios';
import { createBrowserHistory } from 'history';

const USER_LOGIN = process.env.REACT_APP_USER_LOGIN;
const BASE_DEV_URL =  process.env.REACT_APP_BASE_API_OVS;
const history = createBrowserHistory();
const getRefreshTokenAuthHeader = (isForm = false) => {
  const token = localStorage.getItem("refreshToken");
  return {
    "Content-Type": isForm ? "multipart/form-data" : "application/json",
    Token: `Bearer ${token}`,
  };
}
const  fetchRefreshToken = async() => {
  try {
    const url = `${USER_LOGIN}getTokens`;
    const payload = {
      refresh_token: localStorage.getItem("refreshToken"),
    };
    const headers = getRefreshTokenAuthHeader();
    const response = await axios.post(url, payload, { headers });
    if (response?.data) {
      localStorage.setItem("auth_token", response?.data?.id_token);
      const accessToken = response?.data?.access_token;
      localStorage.setItem("accessToken", accessToken);
     // localStorage.setItem("refreshToken", response?.data)
      return response.data;
    }

  
  } catch (error) {
    if (error?.response?.data) {
      console.error("Error refreshing token:", error);
    } else {
      localStorage.clear();
      history.push("/");
      window.location.reload();
    }
   
    throw new Error(
      "Failed to refresh token. Unable to fetch Partner List."
    );
    throw error;
  }
}
const axiosHttp = axios.create({
  baseURL: `${BASE_DEV_URL}`,
});

axiosHttp.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      if (config.headers) {
        config.headers.Token = `Bearer ${accessToken}`;
      }
    }
    return {
      ...config,
      headers: {
        ...(accessToken !== null && { Token: `${accessToken}` }),
        ...config.headers,
      },
    };
  },
  (error) => {
    if (error) {
      if (error?.response?.data) {
        console.error("Error Fetching Partner List:", error);
      } else {
        fetchRefreshToken();
      }
    }
    return Promise.reject(error);
  }
);

axiosHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error) {
      if (error?.response?.data) {
        console.error("Error Fetching Partner List:", error);
      } else {
        fetchRefreshToken();
      }
    }
   
    return Promise.reject(error);
  }
);

export default axiosHttp;
