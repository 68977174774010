import axios from "axios";

export class UserService {
  USER_LOGIN = process.env.REACT_APP_USER_LOGIN;
  constructor() {
    this.url = this.USER_LOGIN;
    // console.log("gg", this.url);
    this.list = null;
  }

  // Check if user is logged in
  static userToken() {
    // console.log(localStorage.getItem("isLoggedIn"));
    if (localStorage.getItem("isLoggedIn")) {
      return true;
    }
    return false;
  }
  async getToken() {
    // Simulate an async token retrieval process (e.g., from localStorage or API)
    return new Promise((resolve) => {
      const token = localStorage.getItem("auth_token"); // Get token from localStorage
      resolve(token); // Return the token after resolving the promise
    });
  }
  async esHeaders() {
    const token = await this.getToken();
    const headers = {
        "Content-Type": "application/json",
        'Token': `Bearer ${token}`,
      };
      // console.log("gg", headers)
    return headers
  }

  // Login method
  async login(params) {
    return axios.post(this.url + "login", params);
  }

  // Get User by Username
  async getUser(value) {
    var data = {
      userName: value ? value : "",
    };
    const token = await this.getToken();
    const headers1 = {
        "Content-Type": "application/json",
        'Token': `Bearer ${token}`,
      };
    const headers = headers1;
    return axios.post(this.url + "getUser", data, { headers });
  }

  // Get User Role
  async getRole(value) {
    const token = await this.getToken();
    const headers1 = {
        "Content-Type": "application/json",
        'Token': `Bearer ${token}`,
      };
    const headers = headers1;
    return axios.get(this.url + `getRole?id=${value}`, {headers});
  }

  // Private method to handle GET requests with headers
  async httpGet(endpoint) {
    const token = await this.getToken();
    const headers1 = {
        "Content-Type": "application/json",
        'Token': `Bearer ${token}`,
      };
    const headers = headers1;
    try {
      const response = await axios.get(this.url + endpoint, {headers});
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Private method to handle POST requests with headers
  async httpPost(endpoint, data) {
    try {
      const response = await axios.post(
        this.url + endpoint,
        data,
        {headers:this.esHeaders()}
      );
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Set headers with token if available

  // Set List data (for state or other use)
  setList(data) {
    this.list = data;
  }

  // Get List data
  getList() {
    return this.list;
  }

  // Error handling
  handleError(error) {
    if (error.response) {
      // Server responded with an error
      console.error("Error Response:", error.response);
    } else if (error.request) {
      // No response was received
      console.error("Error Request:", error.request);
    } else {
      // Something went wrong while setting up the request
      console.error("Error Message:", error.message);
    }
    alert("An error occurred. Please try again.");
  }
}

//export default new UserService();
